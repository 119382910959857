import ImageCapture from "@corneacare/autocapture";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ImageCapture 
        payloadRef={{current: null}} 
        baseModelURL={`${process.env.PUBLIC_URL}/model`}
        useVoiceGuidance
        loaderAnimation={
          <img src="logo.gif" width={"80%"} />
        }
    />
  </React.StrictMode>
);
